import { createApp } from 'vue';
import App from './App.vue';
import router from "@/router";
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import LoadingCircle from '@/views/splash/LoadingCircle.vue';
import VueScrollTo from 'vue-scrollto';
import { ElHeader } from 'element-plus';
import globalErrorHandler from "@/services/global-error.handler";
import VueGtag from "vue-gtag";
import './assets/index.css';

const app = createApp(App);
app.use(router);
app.use(ElementPlus);

app.config.errorHandler = (err, vm, info) => globalErrorHandler.handle(err)

app.use(VueScrollTo, {
    container: "body",
    duration: 800,
    easing: "ease",
    offset: -50,
    cancelable: true,
    onDone: false,
    onCancel: false,
    x: false,
    y: true
});

app.component('ElHeader', ElHeader);
app.component('LoadingCircle', LoadingCircle);

app.use(VueGtag, {
    config: { id: "G-2G8Y2MZW0N" }
});

app.mount('#app');