<template>
  <div>
      <div v-if="showLoader" class="loader-container ">
        <img src="@/assets/loading.gif" alt="" class="loader-img">
      </div>

  </div>
</template>

<style scoped>
.loader-container {
  background-color: rgba(131, 72, 242, 1);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 1.0s;
}

.loader-img {
  max-width: 100px;
  max-height: 100px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 1.0s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.content-container {
  opacity: 1;

}

.content-fade-enter-active, .content-fade-leave-active {
  transition: opacity 1.0s;
}

.content-fade-enter, .content-fade-leave-to {
  opacity: 0;
}
</style>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';

const showLoader = ref(true);

onMounted(() => {
  setTimeout(() => {
    showLoader.value = false;
  }, 2000);
});

onBeforeUnmount(() => {
  showLoader.value = false;
});


</script>