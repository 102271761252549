import {ElMessageBox, ElNotification} from "element-plus";

class Notifier {
  confirm(message: string) {
    return ElMessageBox.confirm(message, 'Confirmação', {
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).catch(() => false);
  }

  error(message: string) {
    ElNotification({
      title: 'Oops, algo deu errado',
      message,
      type: 'error'
    });

    return false;
  }

  success(message: string) {
    ElNotification({
      title: 'Sucesso',
      message,
      type: 'success'
    });
  }
}

export default new Notifier();
