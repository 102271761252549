import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/sitegame/HomeView.vue')
  },
  {
    path: '/notfound',
    name: 'NotFound',
    component: () => import('@/views/NotFound.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/painel/LoginView.vue')
  },
  {
    path: '/pages/:id',
    name: 'render',
    params: true,
    component: () => import('./views/painel/render/RenderPage.vue')
  },
  {
    path: '/painel',
    name: 'painel',
    component: () => import('@/views/painel/PainelView.vue')
  },
  {
    path: '/gamificacao/news',
    name: 'news',
    component: () => import('@/views/sitegamificacao/news/NewsPage.vue')
  },
  {
    path: '/projectcreate/:id',
    component: () => import('@/views/painel/ProjectsView.vue')
  },
  {
    path: '/gamecreate',
    component: () => import('@/views/painel/GamesView.vue')
  },
  {
    path: '/newcreate',
    component: () => import('@/views/painel/NewsView.vue')
  },
  {
    path: '/blogcreate',
    component: () => import('@/views/painel/PagesView.vue')
  },
  {
    path: '/politicadeprivacidade',
    component: () => import('@/views/sitegame/page-games/PolíticaPrivacidade.vue')
  },
  {
    path: '/deletarconta',
    component: () => import('@/views/sitegame/page-games/DeletarConta.vue')
  },
  {
    path: '/theelements',
    component: () => import('@/views/sitegame/page-games/TheElements.vue')
  },
  {
    path: '/gamevozdosoceanos',
    component: () => import('@/views/sitegame/page-games/PlanetSea.vue')
  },
  {
    path: '/boxdungeons',
    component: () => import('@/views/sitegame/page-games/BoxDungeons.vue')
  },
  {
    path: '/linkolnrhyme',
    component: () => import('@/views/sitegame/page-games/LinkolnRhyme.vue')
  },
  {
    path: '/coredefenders',
    component: () => import('@/views/sitegame/page-games/CoreDefenders.vue')
  },
  {
    path: '/popsomeeyes',
    component: () => import('@/views/sitegame/page-games/PopSomeEyes.vue')
  },
  {
    path: '/wardogsredsreturn',
    component: () => import('@/views/sitegame/page-games/WarDogsRedsReturn.vue')
  },
  {
    path: '/willyswonderland',
    component: () => import('@/views/sitegame/page-games/WillysWonderland.vue')
  },
  {
    path: '/arariboiahq',
    component: () => import('@/views/sitegame/page-games/ArariboiaHq.vue')
  },
  {
    path: '/arariboiajogo',
    component: () => import('@/views/sitegame/page-games/ArariboiaJogo.vue')
  },
  {
    path: '/gamificacao/home',
    name: 'gamificacao-home',
    component: () => import('@/views/sitegamificacao/home/HomeView.vue')
  },
  {
    path: '/gamificacao/contact',
    name: 'gamificacao-contact',
    component: () => import('@/views/sitegamificacao/ContactView.vue')
  },
  {
    path: '/gamificacao/projects',
    name: 'gamificacao-projects',
    component: () => import('./views/sitegamificacao/ProjectsPage.vue')
  },
  {
    path: '/',
    redirect: '/home'
  },
  //{
    //path: '/:pathMatch(.*)*',
    //redirect: '/notfound'
  //},
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 };
  }
});

export default router;
