import notifier from "@/services/notifier";

class GlobalErrorHandler {

  handle(error: any) {
    const message = this._formatMessage(error)
    notifier.error(message)
  }

  _formatMessage(error: any) {
    return error?.response?.data?.error
      ?? error?.response?.data?.message
      ?? error?.message
      ?? error?.toString()
      ?? 'Unknown error'
  }
}

export default new GlobalErrorHandler();
